import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile, tablet } from "clutch/src/Style/style.mts";

import { MAPS } from "@/game-cs2-marketing/constants.mts";
import { getMapImage } from "@/game-cs2-marketing/utils.mts";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import Tilting from "@/shared/Tilting.jsx";

const MapsGrid = styled("div")`
  display: grid;
  grid-gap: var(--sp-4);
  grid-template-columns: repeat(3, 1fr);
  ${tablet} {
    grid-template-columns: 50% 50%;
  }
  ${mobile} {
    grid-template-columns: 100%;
  }
`;

const MapBlock = styled("a")`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--shade8);
  border-radius: var(--br-lg);
  img {
    max-width: 100%;
    object-fit: cover;
    border-radius: var(--br-lg) var(--br-lg) 0 0;
  }
  h2 {
    margin: var(--sp-2) 0px;
  }

  &:hover:before {
    content: " ";
    position: absolute;
    z-index: 1;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: var(--sp-0_5) solid var(--shade4);
    border-radius: var(--br-xl);
  }
`;

export default function Maps() {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageHeader title={t("common:navigation.cs2Maps", "CS2 Maps")} />
      <MapsGrid>
        {Object.entries(MAPS)
          .sort(([, { name: a }], [, { name: b }]) => a.localeCompare(b))
          .map(([key, { i18nKey, name }]) => (
            <Tilting key={key}>
              <MapBlock href={`/cs2/database/maps/${key}/overview`}>
                <img src={getMapImage(key)} alt={`${key} image`} />
                <h2 className="type-subtitle1">{t(i18nKey, name)}</h2>
              </MapBlock>
            </Tilting>
          ))}
      </MapsGrid>
    </PageContainer>
  );
}

export function meta() {
  return {
    title: ["cs2:meta.maps.title", "CS2 Interactive Map Guides"],
    description: [
      "cs2:meta.maps.description",
      "Perfect your CS2 gameplay with our dedicated lineups, showcasing the best strategies for each map. Leverage these insights to gain a competitive edge in your matches.",
    ],
    subtitle: true,
  };
}
